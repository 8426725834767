import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import CourseModel from "../Models/CourseModel";
import CourseDetailModel from "../Models/CourseDetailModel";
import ErrorModel from "../Models/ErrorModel";
import AuthModel from "../Models/AuthModel";
import SigninModel from "../Models/SigninModel";
import SignupModel from "../Models/SignupModel";
import ResetPassword from "../Models/ResetPassword";
import SocketModel from "../Models/SocketModel";
import ApplicationModel from "../Models/ApplicationModel";
import fileUploadModel from "../Models/fileUploadModel";

const loadingPlugin = createLoadingPlugin();

const store = init({
    plugins: [loadingPlugin],
    models: { SocketModel, CourseModel, AuthModel, CourseDetailModel, ErrorModel, SigninModel, SignupModel, ResetPassword, ApplicationModel, fileUploadModel }
});

export default store