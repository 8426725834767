import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from './redux/Store/index';
import history from "./history";
import 'antd/dist/antd.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from "./ErrorFallback";
import { isAuthenticated } from "./utils";
import { Helmet } from "react-helmet";
import SignIn from "./pages/SignIn";
import PublicRouter from "./hoc/PublicRouter";
import CommonRouter from "./hoc/CommonRoutes";
import PrivateRouter from "./hoc/PrivateRouter";
import NotFound from "./pages/NotFound";
import './assets/sass/app.scss';

const getUserConfirmation = (dialogKey, callback) => {
  const dialogTrigger = window[Symbol.for(dialogKey)];
  if (dialogTrigger) {
      return dialogTrigger(callback);
  }
  callback(true);
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router getUserConfirmation={() => getUserConfirmation()} history={history}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Switch>
            <App history={history}>
              <Switch>
                <Route
                  exact path="/"
                  render={props =>
                    !isAuthenticated() ? <>
                      <Helmet>
                        <title>{"Sign In"}</title>
                      </Helmet>
                      <SignIn {...props} />
                    </> :
                      <Redirect to={{ pathname: '/home', user: isAuthenticated, state: { from: props.location } }} />
                  }
                />
                {PublicRouter()}
                {CommonRouter()}
                {PrivateRouter()}
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </App>
          </Switch>
        </ErrorBoundary>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
