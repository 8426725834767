import axiosClient from "../../api/axiosClient";

const fileUploadModel = {
    state: {},
    reducers: {},
    effects: (dispatch) => ({
        async uploadFileS3(payload) {
            await axiosClient.post('/upload_file_S3', payload.fileData, payload.config).then(res => {
                if (res.data.status === 200) {
                    payload?.setFile(payload?.type, res.data.url);
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "Error", body: res.data.message });
                }
            }).catch(err => {
                dispatch.ErrorModel.handleErrorPop({ header: "Error", body: err.message });
            }).finally(() => {
                payload.setLoader(false);
            });
        },
    }),
};

export default fileUploadModel;