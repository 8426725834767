import { useEffect } from "react"
import Loader from "../../layout/Loader"

const RedirectPage = ({ history, link }) => {
    
    useEffect(() => {
        localStorage.removeItem('user_payload');
        window.location.href = link;
    }, [])

    return (
        <Loader />
    )
}

export default RedirectPage