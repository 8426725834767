import React ,{ useEffect} from "react";
import store from "../../../redux/Store";

const ApplicationSuccess = () => {
  const userDataSubmitted = localStorage.getItem('userData');
  const { dispatch } = store;

  useEffect(() => {
    if (userDataSubmitted) {
      const jsonObject = JSON.parse(userDataSubmitted);
      const starDate =  jsonObject?.start_date ? jsonObject?.start_date : null
      // dispatch.ApplicationModel.preClgEvent({
      //   ["event"]:"Application Fee Paid-Submitted",
      //   email: jsonObject.email,
      //   user_id: jsonObject._id,
      //   course_id:jsonObject.course_id,
      //   start_date:starDate
      // });
    }
  }, [userDataSubmitted]);
  
  return (
    <div className='course_payment'>
      <h3>Your application has been successfully submitted.</h3>
      <p>
        We will review your application within 3 - 5 business days. Please check your email for further instructions.
      </p>
    </div>
  );
};

export default ApplicationSuccess;
