import { Route } from 'react-router-dom';
import { Helmet } from "react-helmet"
import { commonRoutes } from '../../routes';
import { isAuthenticated } from "../../utils";

const CommonRouter = () => {

    return (
        commonRoutes.map(({ path, Component, title, link }, key) => (
            <Route exact path={path} render={(props) =>
                <>
                    {title &&
                        <Helmet>
                            <title>{title}</title>
                        </Helmet>
                    }
                    <Component link={link} isAuthenticated={isAuthenticated()} {...props} />
                </>
            } key={key} />
        ))
    )
};

export default CommonRouter
