import axiosClient from "../../api/axiosClient";
import history from "../../history";

const CourseModel = {
    state: [],
    reducers: {
        setCourse(state, payload) {
            return [...payload];
        },
    },
    effects: (dispatch) => ({
        async getCourse() {
            let response = await axiosClient.get('/preclg_user_course');
            this.setCourse(response.data.data)
        },
        async userApplication(data) {
            const { temp: payload, setPayload, setStepNumber } = data;
            const { transaction_type, amount, payment_type, ...rest } = payload;
            await axiosClient.post('/add_preclg_user_application', rest).then(res => {
                if (res.data.status === 200) {
                    setPayload({...rest, transaction_type, amount, payment_type, application_id: res?.data?.data?.application_id });
                    setStepNumber(3);
                    //history.push(`/application-submited`);
                    //dispatch.ApplicationModel.getPaymentToken({ ...data, usc_payment_id: res?.data?.data?.application_id });
                } else {
                    dispatch.errorModel.handleErrorPop({ header: "Error", body: res.data.message });
                }
            }).catch(err => {
                dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
            });
        },
        async userApplicationCancel(payload) {
            await axiosClient.put('/update_payment_status', payload).then(res => {
                if (res.data.status === 200) {
                    
                }
            }).catch(err => {
                dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
            }).finally(() => {
                window.location.href = 'https://precollege.usc.edu/';
            });
        },
        async userApplicationPayment(payload) {
            let retry = localStorage.getItem('PaymentRetry');
            await axiosClient.post('/add_preclg_user_application_payment', payload).then(res => {
                if (res.data.status === 200) {
                    localStorage.removeItem('PaymentRetry');
                    history.push(`/application-submited`);
                } else {
                    if (+retry === 1) {
                        localStorage.removeItem('PaymentRetry');
                        dispatch.errorModel.handleErrorPop({ header: "Error", body: res.data.message });
                    } else {
                        localStorage.setItem('PaymentRetry', 1);
                        setTimeout(() => {
                            dispatch.CourseModel.userApplicationPayment(payload);
                        }, 1000);
                    } 
                   
                }
            }).catch(err => {
                dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
            });
        },
        async userFinalPayment(payload) {
            let retry = localStorage.getItem('PaymentRetry');
            await axiosClient.post('/add_course_final_payment', payload).then(res => {
                if (res.data.status === 200) {
                    localStorage.removeItem('PaymentRetry');
                    if (res.data.redirect_page === "registration") {
                        history.push(`/payment-success/${res.data.code}`);
                    } else {
                        history.push(`/payment-success`);
                    }
                } else {
                    if (+retry === 1) {
                        localStorage.removeItem('PaymentRetry');
                        dispatch.errorModel.handleErrorPop({ header: "Error", body: res.data.message });
                    } else {
                        localStorage.setItem('PaymentRetry', 1);
                        setTimeout(() => {
                            dispatch.CourseModel.userFinalPayment(payload);
                        }, 1000);
                    }                    
                }
            }).catch(err => {
                dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
            })
        },
        async addbadgeEvent(payload) {
            await axiosClient.post('/add_pre_badge_event', payload).then(res => {
                if(res.status === 200){
                    console.log("successfully send couse name")
                }
            }).catch(err => {
                dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
            })
        },
    }),
};

export default CourseModel;