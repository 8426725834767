import Cookies from 'universal-cookie';
import axiosClient from "../../api/axiosClient";

const cookies = new Cookies();

const SigninModel = {
    state: {},
    reducers: {
        // handle state changes with pure functions
        setUserDetails(state, payload) {
            return { ...payload };
        },

    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        async verifyUserSignIn(payload) {
            const { token, setFormData, history } = payload;
            axiosClient.get(`/preclg_user_verified/${token}`).then(res => {
                if (res.data.status === 200) {
                    setFormData((old) => { return { ...old, email: res.data.email } });
                } else if (res.data.status === 400) {
                    history.push('/home');
                } else {
                    console.log(`res`, res)
                }
            }).catch(err => {
                console.log(`err`, err)
            })
        },
        async forgotPassword(data) {
            axiosClient.post(`/preclg_forgot_password`, data).then(res => {
                if (res.data.status === 200) {
                    dispatch.ErrorModel.handleErrorPop({ header: "Success", body: "We have sent a reset password link to your e-mail. Check your mail box." });
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "Info", body: res.data.message });
                }
            }).catch(err => {
                dispatch.ErrorModel.handleErrorPop({ header: "Error", body: err.message });
            })
        },
        async logInUser(payload) {
            const { data, history, handleOpenModal, setLoading } = payload;
            axiosClient.post('/Preclguser_signin', data).then(res => {
                setLoading(false);
                if (res.data.status === 200) {
                    const { token, userId } = res.data;
                    this.setUserDetails(res.data.data);
                    console.log(res.data.data)
                    localStorage.setItem('pre_college_userDetails', JSON.stringify(res.data.data));
                    var d = new Date(Date.now() + 12 * (60 * 60 * 1000));
                    cookies.set(`${process.env.REACT_APP_PREFIX}x-access-token`, token, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                    cookies.set(`${process.env.REACT_APP_PREFIX}x-access-token`, token, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                    cookies.set(`${process.env.REACT_APP_PREFIX}u_id`, userId, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                    history.push('/home');
                } else if (res.data.status === 103) {
                    handleOpenModal('ErrorPop', { header: "Email confirmation required FOR", param: data.email, subHeader: data.email, body: 'Before you can access your course, please confirm your account with us. Check your inbox for a confirmation email asking you to confirm your registration.', body2: 'If you need a new confirmation email, please click below.', subBody: 'Resend Account Confirmation Email' });
                } else if (res.data.status === 400) {
                    dispatch.ErrorModel.handleErrorPop({ header: "Info", body: res.data.message });
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "", subHeader: "", className: "signpage_modal exist_email", body: "The email or password you entered is incorrect. Please try again." });
                }
            }).catch(err => {
                setLoading(false);
                dispatch.ErrorModel.handleErrorPop({ header: "LOGIN ERROR", className: "signpage_modal exist_email error_massge", subHeader: "",   body: ` The email or password you entered is incorrect. Please try again.` });
            });
        },
        async reSendMail(data) {
            axiosClient.post('/resend_mail_pre_clg_user', data).then(res => {
                if (res.data.status === 200) {
                    dispatch.ErrorModel.handleErrorPop({ header: "Success", body: res.data.message });
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "Error", body: res.data.message });
                }
            }).catch(err => {
                dispatch.ErrorModel.handleErrorPop({ header: "Error", body: err.data.message });
            });
        },
        async getUserDetails() {
            axiosClient.get('preclg_get_user_detail').then(res => {
                this.setUserDetails(res.data.data);
                localStorage.setItem('pre_college_userDetails', JSON.stringify(res.data.data));
            })
        },
        async getLatestVersion(setUpdatePop, state) {
            await axiosClient.get(`/preclg_get_latest_version`).then(res => {
                if (res.data.status === 200) {
                    let result = res.data.data;
                    if (result.version_number !== state.SigninModel.version) {
                        setUpdatePop(true);
                    }
                }
            });
        },
        async updateVersion(setUpdatePop, state) {
            await axiosClient.put(`/preclg_update_user_version`, {
                "user_id": state.SigninModel._id
            }).then(res => {
                if (res.data.status === 200) {
                    this.setUserDetails(res.data.data);
                    localStorage.setItem('userDetails', JSON.stringify(res.data.data));
                    window.location.reload();
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "error", body: res.data.message });
                }
            }).catch(err => {
                dispatch.ErrorModel.handleErrorPop({ header: "error", body: err.message });
            }).finally(() => {
                setUpdatePop(false)
            });
        },
    }),
};

export default SigninModel;