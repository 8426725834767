import { Redirect, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { publicRoutes } from "../../routes";
import { isAuthenticated } from '../../utils';

const PublicRouter = () => {
    return (
        publicRoutes.map(({ path, Component, title }, key) => (
            <Route exact path={path} render={(props) =>
                !isAuthenticated() ?
                    <>
                        {title &&
                            <Helmet>
                                <title>{title}</title>
                            </Helmet>
                        }
                        <Component {...props} />
                    </>
                    : <Redirect to={{ pathname: '/home', user: isAuthenticated, state: { from: props.location } }} />
            } key={key} />
        ))
    )
};

export default PublicRouter
