import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../../src/assets/images/Logo.svg';
import store from "../../redux/Store";

const Header = ({ isAuthenticated, handleLogout, history }) => {
    const socket = useSelector(state => state.SocketModel.socket);
    const userData = useSelector(state => state.SigninModel);
    const { dispatch } = store;

    useEffect(() => {
        if (Object.keys(socket).length) {
            socket.on('connect', () => {
                console.log('connection');
            });
            socket.off('connect_failed').on('connect_failed', function (err) {
                console.log('Connection Failed', err);
            });
        }
    }, [socket]);

    useEffect(() => {
        if (Object.keys(socket || {}).length) {
            socket.on('new_post_data', socketListener);
        }
        return () => {
            if (Object.keys(socket || {}).length) {
                socket.off('new_post_data', socketListener);
            }
        }
    }, [socket]);

    const socketListener = (res) => {
        const course_id = history.location.pathname.split('/');
        if (res.course_id === course_id?.[2]) {
            switch(res.type) {
                case "preDelete": {
                    dispatch.CourseDetailModel.deleteComment({ ...res.post, call_type: "socket" });
                    break;
                }
                case "preEditComment": {
                    dispatch.CourseDetailModel.editComment(res.post);
                    break;
                }
                case "preComment": {
                    dispatch.CourseDetailModel.addComment({...res.post, call_type: "socket"});
                    break;
                }
                default : {
                    return;
                }
            }
        }
    };

    return (
        <>
            <header>
                <div className="main_header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 top_header">
                                <div onClick={() => history.push('/home')} className="content_head cursor_pointer">
                                    <h1>usc pre-college programs</h1>
                                </div>
                                {isAuthenticated() ?
                                    <div className='header_menu'>
                                        <p className='header_wcm_text'>welcome {userData?.first_name} {userData?.last_name}</p>
                                        <span className='logout_text'><a onClick={handleLogout}>Log out</a></span>
                                    </div>
                                    : ''}
                                <div className="logo">
                                    <img onClick={() => window.open("https://online.usc.edu/")} src={logo} alt="Logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
};

export default Header