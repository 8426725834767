import axiosClient from "../../api/axiosClient";

const ResetPassword = {
    state: {},
    reducers: {
        // handle state changes with pure functions
        setUserDetails(state, payload) {
            return { ...payload };
        },

    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        async verifyUserReset(payload) {
            const { token, setFormData, history } = payload;
            axiosClient.get(`/preclg_verified_reset_pwd_token/${token}`).then(res => {
                if (res.data.status === 200) {
                    setFormData((old) => { return { ...old, email: res.data.email } });
                } else if (res.data.status === 400) {
                    history.push('/home');
                } else {
                    console.log(`res`, res)
                }
            }).catch(err => {
                console.log(`err`, err)
            })
        },
        async resetPassword(payload) {
            const { data, history } = payload;
            axiosClient.put('/preclg_change_password', data).then(res => {
                if (res.data.status === 200) {
                    dispatch.ErrorModel.handleErrorPop({ header: "Success", body: res.data.message });
                    history.push('/');

                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "Error", body: res.data.message });
                }
            }).catch(err => {
                dispatch.ErrorModel.handleErrorPop({ header: "Error", body: err.response.data.message });
            });
        },
    }),
};

export default ResetPassword;