import { useEffect } from 'react';
import store from '../../redux/Store';
import BtnLoader from '../../layout/BtnLoader';

const DeclinePayment = () => {
  const { dispatch } = store;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      switch (params?.payment_type) {
        case 'application': {
          dispatch.CourseModel?.userApplicationCancel({
            status: params.payment_type === 'application' ? "declined" : "",
            id: params.usc_payment_id,
            final_payment_status: params.payment_type === 'course' ? "declined" : "",
          });
          break;
        }
        default: {
          dispatch.CourseModel?.userApplicationCancel({
            status: params.payment_type === 'application' ? "declined" : "",
            id: params.usc_payment_id,
            final_payment_status: params.payment_type === 'course' ? "declined" : "",
          });
          setTimeout(() => {
            window.location.href = 'https://precollege.usc.edu/';
          }, 1000);
          return;
        }
      }
    }
  }, []);

  return (
    <div className='success-screen'>
      <h3>Your payment has been declined.</h3>
      <p>You're being redirected back to https://precollege.usc.edu/.</p>
      <div className='success-screen_loader'>
        <BtnLoader size={'large'} />
      </div>
    </div>
  );
};

export default DeclinePayment;
